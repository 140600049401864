<template>
  <div class="pt-5">
    <b-alert show="" variant="light">
      {{ $t("ACCOUNT.ALERT2") }}
      <strong>{{ $t("EMPLOYEES.DETAILS.SUBMIT") }}</strong></b-alert
    >
    <b-form class="p-3" v-on:submit.prevent="onSubmit">
      <b-form-group
        id="email1"
        label-for="email1"
        :label="this.$t('ACCOUNT.CHANGEEMAIL1')"
        label-class="font-weight-bolder"
        label-cols-sm="4"
        label-cols-lg="3"
      >
        <b-form-input
          id="email1"
          v-model="$v.form.email1.$model"
          :state="validateState('email1')"
          :placeholder="$t('ACCOUNT.CHANGEEMAIL2')"
        ></b-form-input>

        <b-form-invalid-feedback v-if="!$v.form.email1.required">{{
          $t("ALERT.ALERT")
        }}</b-form-invalid-feedback>
      </b-form-group>

      <div class="d-flex justify-content-end pt-3">
        <button
          ref="employee-personal-button"
          class="btn btn-primary font-weight-bolder"
          v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
        >
          {{ $t("EMPLOYEES.DETAILS.SUBMIT") }}
        </button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import swal from "sweetalert";

export default {
  name: "EmployeePersonalDetailForm",
  mixins: [validationMixin],
  data: function () {
    return {
      form: {
        email1: "",
        id: null,
      },
      isBusy: false,
    };
  },
  validations: {
    form: {
      email1: {
        required,
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      console.log("Submit");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      this.isBusy = true;
      this.$store
        .dispatch("changeEmailAddress", this.form.email1)
        .then((data) => {
          this.form.id = data.id;
          swal({
            title: this.$t("ALERT.SUCCESS"),
            //text: "Details Has Been Saved",
            icon: "success",
          });
        })
        .finally(() => (this.isBusy = false));
    },
  },
  computed: {},
};
</script>

<style scoped></style>
