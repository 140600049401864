import { render, staticRenderFns } from "./PersonalAccountEmail.vue?vue&type=template&id=373b9f9d&scoped=true&"
import script from "./PersonalAccountEmail.vue?vue&type=script&lang=js&"
export * from "./PersonalAccountEmail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "373b9f9d",
  null
  
)

export default component.exports